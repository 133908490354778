<template>
 <div class="container">
    <h3>정답팝업 내용</h3>
    <form id="saveForm">
      <div class="form-control">
       제목 <input type="text" required="" v-model="dataForm.q_title">
      </div>
      <div class="form-control">

       내용 <!--<input type="text" required=""  v-model="dataForm.q_text"> -->
        <textarea v-model="dataForm.q_text" style="resize: both;"  cols="30" ></textarea>

      </div>
      <div class="w-32 h-32 border-2 border-dotted border-blue-500">
        (이미지 가로 400 ,세로 400 미만 가능)
        <div v-if="images"
            class="w-full h-full flex items-center">
          <img :src="images" alt="image">
        </div>
        <div v-else
            class="w-full h-full flex items-center justify-center cursor-pointer hover:bg-pink-100"
            >
            <input ref="image" id="input"
                    type="file" name="image" accept="image/*" multiple="multiple"
                    class="hidden"
                    @change="uploadImage()">
          <div style="text-align: center; padding:15px 15px 15px 15px">

            <img

                :src="img_file"

                style="width:90%"
            />
          </div>


          <!--
              <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            -->

        </div>
      </div>

      <div style="text-align: center;">
        <button type="button" class="btn" @click="dataFormSave()" > 적용 </button>&nbsp;
        <button type="button" class="btn" @click="close()" > 닫기 </button>
      </div>
    </form>
  </div>

</template>

<script>
import Vue, { defineComponent } from 'vue'
import { storeToRefs } from 'pinia'
import {useMainStore, useSlidesStore} from '@/store'
import useExport from '@/hooks/useExport'
import axios from 'axios'
import axiosIns from '@/plugins/axios'

export default  defineComponent({

  data() {
    return {
      visible: false,
      dataForm: {
        q_title: '',
        q_text: '',
        q_img: '',
        clTypeContId: '',
        images: '',
      },
      dataRule: {
        q_title: [{ required: true, message: '타입순번 비워둘 수 없습니다.', trigger: 'blur' }],
        q_text: [{ required: true, message: '클래스-타입-컨텐츠 순번 비워둘 수 없습니다.', trigger: 'blur' }],
        q_img: [{ required: true, message: '이미지를 비워둘 수 없습니다.', trigger: 'blur' }]
      }
    }
  },
  methods: {
    close(){
      this.$emit('close')
    },
    init(id) {
      this.dataForm.clTypeContId = id || 0
      this.visible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.clTypeContId) {
          this.$http({
            url: this.$http.adornUrl(`/rest/insertLA40440`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.dataForm.q_title = data.clTypeCont.q_title
              this.dataForm.q_text = data.clTypeCont.q_text
              this.dataForm.q_img = data.clTypeCont.q_img

              alert(this.dataForm.q_img)
            }
          })
        }
      })
    },
    uploadImage: function() {
      let frm = new FormData()
      let image = this.$refs['image'].files[0]
      let data = {filePath: "image"};
      frm.append('file', image)
      //frm.append('file', file_list );
      frm.append('data', new Blob([JSON.stringify(data)], {type : 'application/json'}));

      axios.post('/rest/insertLC0File',frm,  {
        headers: {
          'Content-Type': 'multipart/form-data'
        }})
          .then(response => {
            console.log(response.data.result)
            if (response.data.result == '1') {
              this.dataForm.q_img=response.data.file_cn;
              //alert(response.data.file_cn +":"+handleElementId.value)
              // getImageDataURL(imageFile).then(dataURL => {
              //const props = { src:"https://kls.lms.next-meta.co" + response.data.file_cn }
              //slidesStore.updateElement({ id: handleElementId.value, props })
              //})
            }
          })
    },
    clickInputTag: function() {
      this.$refs['image'].click()
    },
    // dataFormSubmit() {
    //  console.log( ( JSON.stringify(useSlidesStore())))
    // }

    dataFormSave() {

      const mainStore = useMainStore()
      const slidesStore = useSlidesStore()
      const { handleElement, handleElementId } = storeToRefs(mainStore)

      const temp_ = slidesStore.slides

      for (let i = 0; i < temp_.length ; i++) {
        for (let t = 0 ; t < temp_[i].elements.length ; t++ ){
          if(temp_[i].elements[t].id === handleElementId.value){
            temp_[i].elements[t]['q_title'] = this.dataForm.q_title
            temp_[i].elements[t]['q_text'] = this.dataForm.q_text
            temp_[i].elements[t]['q_img'] = this.dataForm.q_img

            break
          }
        }
      }
      alert("적용되었습니다")
      this.close()
/*

      const html_json = null

      const url = window.location.href.split('?')
      const params = url[1].split('&')
      let seq_ty = ''
      let seq_tyc = ''
      for (let i = 0; i < params.length; i++) {
        const temp = params[i].split('=')
        if (temp.length == 2 && temp[0] == 'seq_ty') {
          seq_ty = temp[1]
        }
        if (temp.length == 2 && temp[0] == 'seq_tyc') {
          seq_tyc = temp[1]
        }
      }


      const data = {
        seq_ty: seq_ty,
        seq_tyc: seq_tyc,
        seq_cl: '0',
        html_1: JSON.stringify(jsonResult.slides)

      }

      axios.post('/rest/insertLA40440', data).then(response => {
        console.log(response)
        alert('저장되었습니다')

      })
        .catch(error => {
          console.log(error)
        })



 */
    },
    /*
    // 양식 제출*
    dataFormSubmit() {
      alert(storeToRefs(useSlidesStore()))
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/rest/insertLA40440`),
            method: 'post',
            data: this.$http.adornData({
              'seqTy': '1' || undefined,
              'seqTyc': '1' || undefined,
              'html1': 'html1' || undefined
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '작업성공',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            }
            else {
              this.$message.error(data.msg)
            }
          })
        }
      })
    }
    */
    test(){
      const mainStore = useMainStore()
      const slidesStore = useSlidesStore()
      const { handleElement, handleElementId } = storeToRefs(mainStore)

      const temp_ = slidesStore.slides

      for (let i = 0; i < temp_.length ; i++) {
        for (let t = 0 ; t < temp_[i].elements.length ; t++ ){
          if(temp_[i].elements[t].id === handleElementId.value){
            this.dataForm.q_title = temp_[i].elements[t]['q_title']
                this.dataForm.q_text = temp_[i].elements[t]['q_text']
                    this.dataForm.q_img = temp_[i].elements[t]['q_img']

            break
          }
        }
      }
    },
  },
  computed: {
    img_file(){
      if(this.dataForm.q_img){
        if(this.dataForm.q_img.includes("http")){
          return this.dataForm.q_img;
        }else{

          return 'https://kls.lms.next-meta.co' + this.dataForm.q_img
        }
      }else{
        return 'https://kls.lms.next-meta.co' + '/img/button.png'
      }
    },
  },
  beforeMount() {
    this.test()
  },
})
</script>
<style scoped>

* {
  box-sizing: border-box;
}

body {
  background-color: steelblue;
  color: #fff;
  font-family: 'Muli', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  margin: 0;
}

.container {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 20px 40px;
  border-radius: 5px;
}

.container h3 {
  text-align: center;
  margin-bottom: 30px;
}

.container a {
  text-decoration: none;
  color: lightblue;
}

.btn {
  cursor: pointer;
  display: inline-block;
  width: 40%;
  background: lightblue;
  padding: 15px;
  font-family: inherit;
  font-size: 16px;
  border: 0;
  border-radius: 2px;
}

.btn:focus {
  outline: 0;
}

.btn:active {
  transform: scale(0.98);
}

.text {
  margin-top: 30px;
}

.form-control {
  position: relative;
  margin: 20px 0 40px;
  width: 300px;
}

.form-control input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px #fff solid;
  display: block;
  width: 100%;
  padding: 15px 0;
  font-size: 18px;
  color: #fff;
}

.form-control input:focus,
.form-control input:valid {
  outline: 0;
  border-bottom-color: lightblue;
}

.form-control label {
  position: absolute;
  top: 15px;
  left: 0;
  pointer-events: none;
}

.form-control label span {
  display: inline-block;
  font-size: 18px;
  min-width: 5px;
  transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.form-control input:focus + label span,
.form-control input:valid + label span {
  color: lightblue;
  transform: translateY(-30px);
}

</style>
