
import { defineComponent, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore } from '@/store'
import { PPTElementOutline } from '@/types/slides'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

import ColorButton from './ColorButton.vue'

export default defineComponent({
  name: 'element-outline',
  components: {
    ColorButton,
  },
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const mainStore = useMainStore()
    const slidesStore = useSlidesStore()
    const { handleElement, handleElementId } = storeToRefs(useMainStore())
    const setDialogForQuizPop = mainStore.setDialogForQuizPop
    const outline = ref(false)
    const hasOutline = ref(false)
    const pop_title = ref<string>('')
    const pop_text = ref<string>('')
    const pop_img = ref<string>('')


    const { addHistorySnapshot } = useHistorySnapshot()

    /*
    const updateOutline = (outlineProps: Partial<PPTElementOutline>) => {
      if (!handleElement.value) return
      const props = { outline: { ...outline.value, ...outlineProps } }
      slidesStore.updateElement({ id: handleElement.value.id, props })
      addHistorySnapshot()
    }
*/
    const toggleOutline = (checked: boolean) => {

      if (!handleElement.value) return
      console.log(handleElementId.value)
      if (checked) {
        const temp_ = slidesStore.slides

        for (let i = 0; i < temp_.length ; i++) {
          for (let t = 0 ; t < temp_[i].elements.length ; t++ ){
            if(temp_[i].elements[t].id === handleElementId.value){
              temp_[i].elements[t]['popup_ok'] = 'Y'
              break
            }
          }
        }
        hasOutline.value = true
        outline.value = true

      }
      else {
        const temp_ = slidesStore.slides

        for (let i = 0; i < temp_.length ; i++) {
          for (let t = 0 ; t < temp_[i].elements.length ; t++ ){
            if(temp_[i].elements[t].id === handleElementId.value){
              temp_[i].elements[t]['popup_ok'] = 'N'
              break
            }
          }
        }
        hasOutline.value = false
        outline.value = false
      }
      //addHistorySnapshot()
    }
    const closeQuizPopDialog = () => mainStore.setDialogForQuizPop('')

    //---------------------popup_ok check----------------------
    if(handleElement && handleElement.value && handleElement.value.hasOwnProperty('popup_ok')  && handleElement.value['popup_ok'] && handleElement.value['popup_ok']=='Y') {
      hasOutline.value = true
      outline.value = true

      pop_title.value = handleElement.value['q_title']
      pop_text.value = handleElement.value['q_text']
      pop_img.value = handleElement.value['q_img']
    }else{
      hasOutline.value = false
      outline.value = false
    }

    watch(handleElement, () => {
      if(handleElement && handleElement.value && handleElement.value.hasOwnProperty('popup_ok')  && handleElement.value['popup_ok'] && handleElement.value['popup_ok']=='Y') {
        hasOutline.value = true
        outline.value = true
        pop_title.value = handleElement.value['q_title']
        pop_text.value = handleElement.value['q_text']
        pop_img.value = handleElement.value['q_img']
      }else{
        hasOutline.value = false
        outline.value = false
      }
    })
    //---------------------popup_ok check----------------------
    return {
      outline,
      hasOutline,
      toggleOutline,
      pop_title,
      pop_text,
      pop_img,
      setDialogForQuizPop,
      closeQuizPopDialog,
    }
  },
})
