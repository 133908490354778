<template>
 <div class="container">
  <h3>퀴즈컨텐츠</h3>
  <form id="saveForm">
   <div class="form-control">
    강의제목 <input type="text" required="" v-model="dataForm.seqTy">
   </div>
   <div class="form-control">

    강의컨텐츠
    <textarea v-model="dataForm.seqTyc" style="resize: both;"  cols="50" ></textarea>
   </div>
   <div class="form-control">
    <input type="hidden" required="" v-model="dataForm.html1">
   </div>
   <div>
    <button type="button" class="btn" @click="dataFormSave()" > 저장하기 </button>
   </div>
  </form>
 </div>

</template>

<!--
<template>
 <div class="container">
  <iframe id="ifrm" src="http://localhost:8081/student" width="100%" height="500"></iframe>
 </div>
</template>
<script>
import Vue, { defineComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useSlidesStore } from '@/store'
import useExport from '@/hooks/useExport'
import axios from 'axios'
import axiosIns from '@/plugins/axios'
export default {
  data() {
    return {

    }
  },
  created() {
    //window.location.href="http://localhost:8081/student"
   window.open("http://localhost:8081/student", "", 'top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no');


  }
}
</script>
-->

<script>
import Vue, { defineComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useSlidesStore } from '@/store'
import useExport from '@/hooks/useExport'
import axios from 'axios'
import axiosIns from '@/plugins/axios'

export default {
 data() {
  return {
   visible: false,
   dataForm: {
    seqTy: '',
    seqTyc: '',
    html1: storeToRefs(useSlidesStore()),
    clTypeContId: '',
   },
   dataRule: {
    seqTy: [{ required: true, message: '타입순번 비워둘 수 없습니다.', trigger: 'blur' }],
    seqTyc: [{ required: true, message: '클래스-타입-컨텐츠 순번 비워둘 수 없습니다.', trigger: 'blur' }],
    html1: [{ required: true, message: 'html_1 비워둘 수 없습니다.', trigger: 'blur' }]
   }
  }
 },
 methods: {
  init(id) {
   this.dataForm.clTypeContId = id || 0
   this.visible = true
   this.$nextTick(() => {
    this.$refs['dataForm'].resetFields()
    if (this.dataForm.clTypeContId) {
     this.$http({
      url: this.$http.adornUrl(`/rest/insertLA40440`),
      method: 'get',
      params: this.$http.adornParams()
     }).then(({data}) => {
      if (data && data.code === 0) {
       this.dataForm.seqTy = data.clTypeCont.seqTy
       this.dataForm.seqTyc = data.clTypeCont.seqTyc
       this.dataForm.html1 = data.clTypeCont.html1
      }
     })
    }
   })
  },
  // dataFormSubmit() {
  //  console.log( ( JSON.stringify(useSlidesStore())))
  // }

  dataFormSave() {

   const jsonResult = useSlidesStore()


   const html_json = null

   const url = window.location.href.split('?')
   const params = url[1].split('&')
   let seq_ty = ''
   let seq_tyc = ''
   for (let i = 0; i < params.length; i++) {
    const temp = params[i].split('=')
    if (temp.length == 2 && temp[0] == 'seq_ty') {
     seq_ty = temp[1]
    }
    if (temp.length == 2 && temp[0] == 'seq_tyc') {
     seq_tyc = temp[1]
    }
   }


   const data = {
    seq_ty: seq_ty,
    seq_tyc: seq_tyc,
    seq_cl: '0',
    html_1: JSON.stringify(jsonResult.slides)

   }

   axios.post('/rest/insertLA40440', data).then(response => {
    console.log(response)
    alert('저장되었습니다')

   })
       .catch(error => {
        console.log(error)
       })


  }
  /*
  // 양식 제출*
  dataFormSubmit() {
    alert(storeToRefs(useSlidesStore()))
    this.$refs['dataForm'].validate((valid) => {
      if (valid) {
        this.$http({
          url: this.$http.adornUrl(`/rest/insertLA40440`),
          method: 'post',
          data: this.$http.adornData({
            'seqTy': '1' || undefined,
            'seqTyc': '1' || undefined,
            'html1': 'html1' || undefined
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '작업성공',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.visible = false
                this.$emit('refreshDataList')
              }
            })
          }
          else {
            this.$message.error(data.msg)
          }
        })
      }
    })
  }
  */
  }
}


</script>
<style scoped>

* {
 box-sizing: border-box;
}

body {
 background-color: steelblue;
 color: #fff;
 font-family: 'Muli', sans-serif;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 height: 100vh;
 overflow: hidden;
 margin: 0;
}

.container {
 background-color: rgba(0, 0, 0, 0.4);
 padding: 20px 40px;
 border-radius: 5px;
}

.container h3 {
 text-align: center;
 margin-bottom: 30px;
}

.container a {
 text-decoration: none;
 color: lightblue;
}

.btn {
 cursor: pointer;
 display: inline-block;
 width: 100%;
 background: lightblue;
 padding: 15px;
 font-family: inherit;
 font-size: 16px;
 border: 0;
 border-radius: 5px;
}

.btn:focus {
 outline: 0;
}

.btn:active {
 transform: scale(0.98);
}

.text {
 margin-top: 30px;
}

.form-control {
 position: relative;
 margin: 20px 0 40px;
 width: 300px;
}

.form-control input {
 background-color: transparent;
 border: 0;
 border-bottom: 2px #fff solid;
 display: block;
 width: 100%;
 padding: 15px 0;
 font-size: 18px;
 color: #fff;
}

.form-control input:focus,
.form-control input:valid {
 outline: 0;
 border-bottom-color: lightblue;
}

.form-control label {
 position: absolute;
 top: 15px;
 left: 0;
 pointer-events: none;
}

.form-control label span {
 display: inline-block;
 font-size: 18px;
 min-width: 5px;
 transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.form-control input:focus + label span,
.form-control input:valid + label span {
 color: lightblue;
 transform: translateY(-30px);
}

</style>
