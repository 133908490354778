import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { nanoid } from 'nanoid'
import { useMainStore, useSlidesStore } from '@/store'
import { Slide } from '@/types/slides'
import { copyText, readClipboard } from '@/utils/clipboard'
import { encrypt } from '@/utils/crypto'
import { createElementIdMap } from '@/utils/element'
import { KEYS } from '@/configs/hotkey'
import { message } from 'ant-design-vue'
import usePasteTextClipboardData from '@/hooks/usePasteTextClipboardData'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'
import useAddSlidesOrElements from '@/hooks//useAddSlidesOrElements'

export default () => {
  const mainStore = useMainStore()
  const slidesStore = useSlidesStore()
  const { selectedSlidesIndex: _selectedSlidesIndex, activeElementIdList } = storeToRefs(mainStore)
  const { currentSlide, slides, theme, slideIndex } = storeToRefs(slidesStore)

  const selectedSlidesIndex = computed(() => [..._selectedSlidesIndex.value, slideIndex.value])
  const selectedSlides = computed(() => slides.value.filter((item, index) => selectedSlidesIndex.value.includes(index)))
  const selectedSlidesId = computed(() => selectedSlides.value.map(item => item.id))

  const { pasteTextClipboardData } = usePasteTextClipboardData()
  const { addSlidesFromData } = useAddSlidesOrElements()
  const { addHistorySnapshot } = useHistorySnapshot()

  // 슬라이드 리셋
  //elements: [{"type":"image","id":"0krAVAQBNg"  ,"src":"https://kls.lms.next-meta.co/bk_intro.png","width":760,"height":1143.4955145118734,"left":0,"top":-6,"fixedRatio":true,"rotate":0},{"type":"text","id":"4H5Nv8_ofq","left":49,"top":24,"width":662,"height":164,"content":"<p style=\"\"><strong><span style=\"color: rgb(0, 0, 0);\"><span style=\"background-color: rgb(244, 247, 249);\"><span style=\"font-size: 48px\"><span style=\"font-family: Plus Jakarta Sans, sans-serif\">What's your goal for this class?</span></span></span></span></strong></p>","rotate":0,"defaultFontName":"Microsoft Yahei","defaultColor":"#333"},{"type":"text","id":"8SWTgANnRE","left":240,"top":268,"width":280,"height":62,"content":"<p style=\"\"><strong><span style=\"font-size: 16px\">모쏠/팩폭</span></strong></p>","rotate":0,"defaultFontName":"Microsoft Yahei","defaultColor":"#333"},{"type":"text","id":"OcStRbniaV","left":240,"top":412,"width":280,"height":62,"content":"<p style=\"\"><strong><span style=\"font-size: 16px\">모쏠/팩폭</span></strong></p>","rotate":0,"defaultFontName":"Microsoft Yahei","defaultColor":"#333"},{"type":"text","id":"2AH9tTd8vD","left":240,"top":548,"width":280,"height":62,"content":"<p style=\"\"><strong><span style=\"font-size: 16px\">모쏠/팩폭</span></strong></p>","rotate":0,"defaultFontName":"Microsoft Yahei","defaultColor":"#333"},{"type":"text","id":"cb5iuhWHJS","left":240,"top":698,"width":280,"height":62,"content":"<p style=\"\"><strong><span style=\"font-size: 16px\">모쏠/팩폭</span></strong></p>","rotate":0,"defaultFontName":"Microsoft Yahei","defaultColor":"#333"},{"type":"image","id":"wwZC7Zwxc7","src":"https://kls.lms.next-meta.co/start_button.png","width":640,"height":98.15294117647059,"left":71,"top":1000,"fixedRatio":true,"rotate":0}],
  const resetSlides = () => {
    const emptySlide: Slide = {
      id: nanoid(10),
      elements: [{"type":"image","id":"0krAVAQBNg"  ,"src":"https://kls.lms.next-meta.co/bk_intro.png","width":760,"height":1143.4955145118734,"left":0,"top":-6,"fixedRatio":true,"rotate":0},{"type":"text","id":"4H5Nv8_ofq","left":49,"top":24,"width":662,"height":164,"content":"<p style=\"\"><strong><span style=\"color: rgb(0, 0, 0);\"><span style=\"background-color: rgb(244, 247, 249);\"><span style=\"font-size: 48px\"><span style=\"font-family: Plus Jakarta Sans, sans-serif\">What's your goal for this class?</span></span></span></span></strong></p>","rotate":0,"defaultFontName":"Microsoft Yahei","defaultColor":"#333"},{"type":"text","id":"8SWTgANnRE","left":240,"top":268,"width":280,"height":62,"content":"<p style=\"\"><strong><span style=\"font-size: 16px\">모쏠/팩폭</span></strong></p>","rotate":0,"defaultFontName":"Microsoft Yahei","defaultColor":"#333"},{"type":"text","id":"OcStRbniaV","left":240,"top":412,"width":280,"height":62,"content":"<p style=\"\"><strong><span style=\"font-size: 16px\">모쏠/팩폭</span></strong></p>","rotate":0,"defaultFontName":"Microsoft Yahei","defaultColor":"#333"},{"type":"text","id":"2AH9tTd8vD","left":240,"top":548,"width":280,"height":62,"content":"<p style=\"\"><strong><span style=\"font-size: 16px\">모쏠/팩폭</span></strong></p>","rotate":0,"defaultFontName":"Microsoft Yahei","defaultColor":"#333"},{"type":"text","id":"cb5iuhWHJS","left":240,"top":698,"width":280,"height":62,"content":"<p style=\"\"><strong><span style=\"font-size: 16px\">모쏠/팩폭</span></strong></p>","rotate":0,"defaultFontName":"Microsoft Yahei","defaultColor":"#333"},{"type":"image","id":"wwZC7Zwxc7","src":"https://kls.lms.next-meta.co/start_button.png","width":640,"height":98.15294117647059,"left":71,"top":1000,"fixedRatio":true,"rotate":0}],
      content_id: "content_0001",
      background: {
        type: 'solid',
        color: theme.value.backgroundColor,
      },
    }
    slidesStore.updateSlideIndex(0)
    mainStore.setActiveElementIdList([])
    slidesStore.setSlides([emptySlide])
  }
//
  /**
   * 페이지 초점 이동
   * @param command 페이지 초점 이동 명령: 위로 이동, 아래로 이동
   */
  const updateSlideIndex = (command: string) => {
    if (command === KEYS.UP && slideIndex.value > 0) {
      if (activeElementIdList.value.length) mainStore.setActiveElementIdList([])
      slidesStore.updateSlideIndex(slideIndex.value - 1)
    }
    else if (command === KEYS.DOWN && slideIndex.value < slides.value.length - 1) {
      if (activeElementIdList.value.length) mainStore.setActiveElementIdList([])
      slidesStore.updateSlideIndex(slideIndex.value + 1)
    }
  }

  // 현재 페이지 데이터를 암호화하여 클립보드에 복사하기
  const copySlide = () => {
    const text = encrypt(JSON.stringify({
      type: 'slides',
      data: selectedSlides.value,
    }))

    copyText(text).then(() => {
      mainStore.setThumbnailsFocus(true)
    })
  }

  // 클립보드 페이지의 데이터를 복호화하여 다음 페이지에 추가해 보십시오 (붙여넣기)
  const pasteSlide = () => {
    readClipboard().then(text => {
      pasteTextClipboardData(text, { onlySlide: true })
    }).catch(err => message.warning(err))
  }

  // 빈 페이지를 만들고 다음 페이지에 추가하기
  const createSlide = () => {
    const emptySlide: Slide = {
      id: nanoid(10),
      elements: [],
      background: {
        type: 'solid',
        color: theme.value.backgroundColor,
      },
    }
    mainStore.setActiveElementIdList([])
    slidesStore.addSlide(emptySlide)
    addHistorySnapshot()
  }

  // 템플릿에 따라 새 페이지 만들기
  const createSlideByTemplate = (slide: Slide) => {
    const { groupIdMap, elIdMap } = createElementIdMap(slide.elements)

    for (const element of slide.elements) {
      element.id = elIdMap[element.id]
      if (element.groupId) element.groupId = groupIdMap[element.groupId]
    }

    if (slide.id === 'SLIDE_0001') {

      const newSlide = {
        ...slide,
        id: nanoid(10),
        content_id: 'content_0001',
      }
      localStorage.setItem('SLIDE_0001', localStorage.getItem('SLIDE_0001') + ',' + newSlide.id)
      mainStore.setActiveElementIdList([])
      slidesStore.addSlide(newSlide)
      addHistorySnapshot()
    }else if (slide.id === 'SLIDE_0011') {

      const newSlide = {
        ...slide,
        id: nanoid(10),
        content_id: 'content_0011',
      }
      localStorage.setItem('SLIDE_0011', localStorage.getItem('SLIDE_0011') + ',' + newSlide.id)
      mainStore.setActiveElementIdList([])
      slidesStore.addSlide(newSlide)
      addHistorySnapshot()
    }else if (slide.id === 'SLIDE_0002') {

      const newSlide = {
        ...slide,
        id: nanoid(10),
        content_id: 'content_0002',
      }
      localStorage.setItem('SLIDE_0002', localStorage.getItem('SLIDE_0002') + ',' + newSlide.id)
      mainStore.setActiveElementIdList([])
      slidesStore.addSlide(newSlide)
      addHistorySnapshot()
    }else if (slide.id === 'SLIDE_0003') {

      const newSlide = {
        ...slide,
        id: nanoid(10),
        content_id: 'content_0003',
      }
      localStorage.setItem('SLIDE_0003', localStorage.getItem('SLIDE_0003') + ',' + newSlide.id)
      mainStore.setActiveElementIdList([])
      slidesStore.addSlide(newSlide)
      addHistorySnapshot()
    }else if (slide.id === 'SLIDE_0013') {

      const newSlide = {
        ...slide,
        id: nanoid(10),
        content_id: 'content_0013',
      }
      localStorage.setItem('SLIDE_0013', localStorage.getItem('SLIDE_0013') + ',' + newSlide.id)
      mainStore.setActiveElementIdList([])
      slidesStore.addSlide(newSlide)
      addHistorySnapshot()
    }else if (slide.id === 'SLIDE_0004') {

      const newSlide = {
        ...slide,
        id: nanoid(10),
        content_id: 'content_0004',
      }
      localStorage.setItem('SLIDE_0004', localStorage.getItem('SLIDE_0004') + ',' + newSlide.id)
      mainStore.setActiveElementIdList([])
      slidesStore.addSlide(newSlide)
      addHistorySnapshot()
    }else if(slide.id === 'SLIDE_0014') {

      const newSlide = {
        ...slide,
        id: nanoid(10),
        content_id: 'content_0014',
      }
      localStorage.setItem('SLIDE_0014', localStorage.getItem('SLIDE_0014') + ',' + newSlide.id)
      mainStore.setActiveElementIdList([])
      slidesStore.addSlide(newSlide)
      addHistorySnapshot()
    }else if(slide.id === 'SLIDE_0006') {

      const newSlide = {
        ...slide,
        id: nanoid(10),
        content_id: 'content_0006',
      }
      localStorage.setItem('SLIDE_0006', localStorage.getItem('SLIDE_0006') + ',' + newSlide.id)
      mainStore.setActiveElementIdList([])
      slidesStore.addSlide(newSlide)
      addHistorySnapshot()
    }else if(slide.id === 'SLIDE_0005') {

      const newSlide = {
        ...slide,
        id: nanoid(10),
        content_id: 'content_0005',
      }
      localStorage.setItem('SLIDE_0005', localStorage.getItem('SLIDE_0005') + ',' + newSlide.id)
      mainStore.setActiveElementIdList([])
      slidesStore.addSlide(newSlide)
      addHistorySnapshot()
    }else if(slide.id === 'SLIDE_0007') {

      const newSlide = {
        ...slide,
        id: nanoid(10),
        content_id: 'content_0007',
        quiz_1: '1',
        quiz_2: '2',
        quiz_3: '3',
      }
      localStorage.setItem('SLIDE_0007', localStorage.getItem('SLIDE_0007') + ',' + newSlide.id)
      mainStore.setActiveElementIdList([])
      slidesStore.addSlide(newSlide)
      addHistorySnapshot()
    }else if(slide.id === 'SLIDE_0017') {

      const newSlide = {
        ...slide,
        id: nanoid(10),
        content_id: 'content_0017',
        quiz_1: '1',
        quiz_2: '1',
        quiz_3: '1',
        quiz_4: '1',
      }
      localStorage.setItem('SLIDE_0017', localStorage.getItem('SLIDE_0017') + ',' + newSlide.id)
      mainStore.setActiveElementIdList([])
      slidesStore.addSlide(newSlide)
      addHistorySnapshot()
    }else if(slide.id === 'SLIDE_0008') {

      const newSlide = {
        ...slide,
        id: nanoid(10),
        content_id: 'content_0008',
      }
      localStorage.setItem('SLIDE_0008', localStorage.getItem('SLIDE_0008') + ',' + newSlide.id)
      mainStore.setActiveElementIdList([])
      slidesStore.addSlide(newSlide)
      addHistorySnapshot()
    }else if(slide.id === 'SLIDE_0015') {

      const newSlide = {
        ...slide,
        id: nanoid(10),
        content_id: 'content_0015',
      }
      localStorage.setItem('SLIDE_0015', localStorage.getItem('SLIDE_0015') + ',' + newSlide.id)
      mainStore.setActiveElementIdList([])
      slidesStore.addSlide(newSlide)
      addHistorySnapshot()
    }


  }

  // 현재 페이지를 다음 페이지로 복사
  const copyAndPasteSlide = () => {
    const slide = JSON.parse(JSON.stringify(currentSlide.value))
    addSlidesFromData([slide])
  }

  // 현재 페이지를 삭제합니다. 모든 페이지를 삭제할 경우 슬라이드 재설정을 수행합니다.
  const deleteSlide = (targetSlidesId = selectedSlidesId.value) => {
    if (slides.value.length === targetSlidesId.length) resetSlides()
    else slidesStore.deleteSlide(targetSlidesId)

    mainStore.updateSelectedSlidesIndex([])

    addHistorySnapshot()

  }

  // 현재 페이지 복사 후 삭제( 잘라내기)
  // 복사 작업으로 인해 다중 선택 상태가 사라지므로 삭제해야 할 페이지 ID를 미리 캐시해야 합니다.
  const cutSlide = () => {
    const targetSlidesId = [...selectedSlidesId.value]
    copySlide()
    deleteSlide(targetSlidesId)
  }

  // 모든 슬라이드 선택
  const selectAllSlide = () => {
    const newSelectedSlidesIndex = Array.from(Array(slides.value.length), (item, index) => index)
    mainStore.setActiveElementIdList([])
    mainStore.updateSelectedSlidesIndex(newSelectedSlidesIndex)
  }

  return {
    resetSlides,
    updateSlideIndex,
    copySlide,
    pasteSlide,
    createSlide,
    createSlideByTemplate,
    copyAndPasteSlide,
    deleteSlide,
    cutSlide,
    selectAllSlide,
  }
}
