
import { defineComponent, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore } from '@/store'
import { PPTTextElement } from '@/types/slides'
import emitter, { EmitterEvents, RichTextAction } from '@/utils/emitter'
import { WEB_FONTS } from '@/configs/font'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'
import { message } from 'ant-design-vue'

import ElementOpacity from '../common/ElementOpacity.vue'
import ElementOutline from '../common/ElementOutline.vue'
import ElementShadow from '../common/ElementShadow.vue'
import ColorButton from '../common/ColorButton.vue'
import ElementQuizPopupSetting from '@/views/Editor/Toolbar/common/ElementQuizPopupSetting.vue'
import {nanoid} from 'nanoid'

const presetStyles = [
  {
    label: '큰제목',
    style: {
      fontSize: '26px',
      fontWeight: 700,
    },
    cmd: [
      { command: 'clear' },
      { command: 'fontsize', value: '48px' },
      { command: 'align', value: 'center' },
      { command: 'bold' },
    ],
  },
  {
    label: '부제목',
    style: {
      fontSize: '22px',
      fontWeight: 700,
    },
    cmd: [
      { command: 'clear' },
      { command: 'fontsize', value: '36px' },
      { command: 'align', value: 'center' },
      { command: 'bold' },
    ],
  },
  {
    label: '본문',
    style: {
      fontSize: '20px',
    },
    cmd: [
      { command: 'clear' },
      { command: 'fontsize', value: '20px' },
    ],
  },
  {
    label: '본문[소]',
    style: {
      fontSize: '18px',
    },
    cmd: [
      { command: 'clear' },
      { command: 'fontsize', value: '18px' },
    ],
  },
  {
    label: '주석첨가 1',
    style: {
      fontSize: '16px',
      fontStyle: 'italic',
    },
    cmd: [
      { command: 'clear' },
      { command: 'fontsize', value: '16px' },
      { command: 'em' },
    ],
  },
  {
    label: '주석첨가 2',
    style: {
      fontSize: '16px',
      textDecoration: 'underline',
    },
    cmd: [
      { command: 'clear' },
      { command: 'fontsize', value: '16px' },
      { command: 'underline' },
    ],
  },
]

const webFonts = WEB_FONTS

export default defineComponent({
  name: 'text-style-panel',
  components: {
    ElementOpacity,
    ElementOutline,
    ElementShadow,
    ColorButton,
    ElementQuizPopupSetting,
  },
  setup: function() {
    const slidesStore = useSlidesStore()
    const { currentSlide } = storeToRefs(slidesStore)
    const {handleElement, handleElementId, richTextAttrs, availableFonts} = storeToRefs(useMainStore())

    const {addHistorySnapshot} = useHistorySnapshot()

    const updateElement = (props: Partial<PPTTextElement>) => {
      slidesStore.updateElement({id: handleElementId.value, props})
      addHistorySnapshot()
    }

    const fill = ref<string>('#000')
    const lineHeight = ref<number>()
    const wordSpace = ref<number>()
    const textIndent = ref<number>()
    const paragraphSpace = ref<number>()
    const checkLadder = ref(false)
    const checkLadder2 = ref(false)
    const quiz_1_value = ref<string>('')
    const quiz_2_value = ref<string>('')
    const quiz_3_value = ref<string>('')
    const quiz_4_value = ref<string>('')
    const selectQuiz = ref<number>()
    const pop_title = ref<string>('')
    //console.log(JSON.stringify(currentSlide.value));

    if((currentSlide.value.content_id) === "content_0007") {
      console.log(333+":"+currentSlide.value['quiz_1'])
      checkLadder.value=true
      checkLadder2.value=false
      quiz_1_value.value = currentSlide.value['quiz_1']
      quiz_2_value.value = currentSlide.value['quiz_2']
      quiz_3_value.value = currentSlide.value['quiz_3']
    }else if((currentSlide.value.content_id) === "content_0017") {
      console.log(2222+":"+currentSlide.value['quiz_1'])
      checkLadder.value=true
      checkLadder2.value=true
      quiz_1_value.value = currentSlide.value['quiz_1']
      quiz_2_value.value = currentSlide.value['quiz_2']
      quiz_3_value.value = currentSlide.value['quiz_3']
      quiz_4_value.value = currentSlide.value['quiz_4']
    }else{
      checkLadder.value=false
      checkLadder2.value=false
    }



    //---------------------사다리 줄긋기 체크----------------------



    if(handleElement && handleElement.value  ) {
      //console.log( "hahhaaha"+JSON.stringify(  handleElement.value) )
      if( handleElement.value.hasOwnProperty('left_id')  &&  handleElement.value['left_id']=='1'){
        selectQuiz.value = 1
      }else if( handleElement.value.hasOwnProperty('left_id')  &&  handleElement.value['left_id']=='2'){
        selectQuiz.value = 2
      }else if( handleElement.value.hasOwnProperty('left_id')  &&  handleElement.value['left_id']=='3'){
        selectQuiz.value = 3
      }else if( handleElement.value.hasOwnProperty('left_id')  &&  handleElement.value['left_id']=='4'){
        selectQuiz.value = 4
      }else if( handleElement.value.hasOwnProperty('right_id')  &&  handleElement.value['right_id']=='1'){
        if(selectQuiz.value === 1) {
          quiz_1_value.value = '1'
        }else if(selectQuiz.value === 2) {
          quiz_2_value.value = '1'
        }else if(selectQuiz.value === 3) {
          quiz_3_value.value = '1'
        }else if(selectQuiz.value === 4) {
          quiz_4_value.value = '1'
        }
      }else if( handleElement.value.hasOwnProperty('right_id')  &&  handleElement.value['right_id']=='2'){
        if(selectQuiz.value === 1) {
          quiz_1_value.value = '2'
        }else if(selectQuiz.value === 2) {
          quiz_2_value.value = '2'
        }else if(selectQuiz.value === 3) {
          quiz_3_value.value = '2'
        }else if(selectQuiz.value === 4) {
          quiz_4_value.value = '2'
        }
      }else if( handleElement.value.hasOwnProperty('right_id')  &&  handleElement.value['right_id']=='3'){
        if(selectQuiz.value === 1) {
          quiz_1_value.value = '3'
        }else if(selectQuiz.value === 2) {
          quiz_2_value.value = '3'
        }else if(selectQuiz.value === 3) {
          quiz_3_value.value = '3'
        }else if(selectQuiz.value === 4) {
          quiz_4_value.value = '3'
        }
      }else if( handleElement.value.hasOwnProperty('right_id')  &&  handleElement.value['right_id']=='4'){
        if(selectQuiz.value === 1) {
          quiz_1_value.value = '4'
        }else if(selectQuiz.value === 2) {
          quiz_2_value.value = '4'
        }else if(selectQuiz.value === 3) {
          quiz_3_value.value = '4'
        }else if(selectQuiz.value === 4) {
          quiz_4_value.value = '4'
        }
      }

      const temp_ = slidesStore.slides
      console.log(currentSlide.value.content_id +":"+currentSlide.value.id )
      if(currentSlide.value.content_id==="content_0007"){
        currentSlide.value['quiz_1']=quiz_1_value.value;
        currentSlide.value['quiz_2']=quiz_2_value.value;
        currentSlide.value['quiz_3']=quiz_3_value.value;
      }else if(currentSlide.value.content_id==="content_0017"){
        currentSlide.value['quiz_1']=quiz_1_value.value;
        currentSlide.value['quiz_2']=quiz_2_value.value;
        currentSlide.value['quiz_3']=quiz_3_value.value;
        currentSlide.value['quiz_4']=quiz_4_value.value;
      }
    }



    watch(handleElementId, () => {
      if(handleElement && handleElement.value  ) {
        //console.log( "hahhaaha"+JSON.stringify(  handleElement.value) )
        if( handleElement.value.hasOwnProperty('left_id')  &&  handleElement.value['left_id']=='1'){
          selectQuiz.value = 1
        }else if( handleElement.value.hasOwnProperty('left_id')  &&  handleElement.value['left_id']=='2'){
          selectQuiz.value = 2
        }else if( handleElement.value.hasOwnProperty('left_id')  &&  handleElement.value['left_id']=='3'){
          selectQuiz.value = 3
        }else if( handleElement.value.hasOwnProperty('left_id')  &&  handleElement.value['left_id']=='4'){
          selectQuiz.value = 4
        }else if( handleElement.value.hasOwnProperty('right_id')  &&  handleElement.value['right_id']=='1'){
          if(selectQuiz.value === 1) {
            quiz_1_value.value = '1'
          }else if(selectQuiz.value === 2) {
            quiz_2_value.value = '1'
          }else if(selectQuiz.value === 3) {
            quiz_3_value.value = '1'
          }else if(selectQuiz.value === 4) {
            quiz_4_value.value = '1'
          }
        }else if( handleElement.value.hasOwnProperty('right_id')  &&  handleElement.value['right_id']=='2'){
          if(selectQuiz.value === 1) {
            quiz_1_value.value = '2'
          }else if(selectQuiz.value === 2) {
            quiz_2_value.value = '2'
          }else if(selectQuiz.value === 3) {
            quiz_3_value.value = '2'
          }else if(selectQuiz.value === 4) {
            quiz_4_value.value = '2'
          }
        }else if( handleElement.value.hasOwnProperty('right_id')  &&  handleElement.value['right_id']=='3'){
          if(selectQuiz.value === 1) {
            quiz_1_value.value = '3'
          }else if(selectQuiz.value === 2) {
            quiz_2_value.value = '3'
          }else if(selectQuiz.value === 3) {
            quiz_3_value.value = '3'
          }else if(selectQuiz.value === 4) {
            quiz_4_value.value = '3'
          }
        }else if( handleElement.value.hasOwnProperty('right_id')  &&  handleElement.value['right_id']=='4'){
          if(selectQuiz.value === 1) {
            quiz_1_value.value = '4'
          }else if(selectQuiz.value === 2) {
            quiz_2_value.value = '4'
          }else if(selectQuiz.value === 3) {
            quiz_3_value.value = '4'
          }else if(selectQuiz.value === 4) {
            quiz_4_value.value = '4'
          }
        }


        const temp_ = slidesStore.slides
        console.log(currentSlide.value.content_id +":"+currentSlide.value.id )
        if(currentSlide.value.content_id==="content_0007"){
          currentSlide.value['quiz_1']=quiz_1_value.value;
          currentSlide.value['quiz_2']=quiz_2_value.value;
          currentSlide.value['quiz_3']=quiz_3_value.value;
        }else if(currentSlide.value.content_id==="content_0017"){
          currentSlide.value['quiz_1']=quiz_1_value.value;
          currentSlide.value['quiz_2']=quiz_2_value.value;
          currentSlide.value['quiz_3']=quiz_3_value.value;
          currentSlide.value['quiz_4']=quiz_4_value.value;
        }


      }
    })
    //---------------------사다리 줄긋기 체크----------------------


    watch(handleElement, () => {
      if (!handleElement.value || handleElement.value.type !== 'text') return

      fill.value = handleElement.value.fill || '#fff'
      lineHeight.value = handleElement.value.lineHeight || 1.5
      wordSpace.value = handleElement.value.wordSpace || 0
      textIndent.value = handleElement.value.textIndent || 0
      paragraphSpace.value = handleElement.value.paragraphSpace === undefined ? 5 : handleElement.value.paragraphSpace
    }, {deep: true, immediate: true})

    const fontSizeOptions = [
      '12px', '14px', '16px', '18px', '20px', '22px', '24px', '28px', '32px',
      '36px', '40px', '44px', '48px', '54px', '60px', '66px', '72px', '76px',
      '80px', '88px', '96px', '104px', '112px', '120px',
    ]
    const lineHeightOptions = [0.9, 1.0, 1.15, 1.2, 1.4, 1.5, 1.8, 2.0, 2.5, 3.0]
    const wordSpaceOptions = [0, 1, 2, 3, 4, 5, 6, 8, 10]
    const textIndentOptions = [0, 48, 96, 144, 192, 240, 288, 336]
    const paragraphSpaceOptions = [0, 5, 10, 15, 20, 25, 30, 40, 50, 80]

    // 행 높이 설정
    const updateLineHeight = (value: number) => {
      updateElement({lineHeight: value})
    }

    // 세그먼트 간격 설정
    const updateParagraphSpace = (value: number) => {
      updateElement({paragraphSpace: value})
    }

    // 글자 간격 설정
    const updateWordSpace = (value: number) => {
      updateElement({wordSpace: value})
    }

    // 첫 줄 들여쓰기 설정
    const updateTextIndent = (value: number) => {
      updateElement({textIndent: value})
    }

    // 텍스트 상자 채우기 설정
    const updateFill = (value: string) => {
      updateElement({fill: value})
    }

    // 리치 텍스트 설정 명령 보내기
    const emitRichTextCommand = (command: string, value?: string) => {
      emitter.emit(EmitterEvents.RICH_TEXT_COMMAND, {action: {command, value}})
    }

    // 리치 텍스트 설정 명령 보내기 (배치)
    const emitBatchRichTextCommand = (action: RichTextAction[]) => {
      emitter.emit(EmitterEvents.RICH_TEXT_COMMAND, {action})
    }

    const question_ok = () => {
     // alert(handleElementId.value)
     // alert((<HTMLInputElement>document.getElementById("text_value")).value)

      const temp_ = slidesStore.slides

        for (let i = 0; i < temp_.length ; i++) {
            for (let t = 0 ; t < temp_[i].elements.length ; t++ ){
                if(temp_[i].elements[t].id === handleElementId.value){
                  temp_[i].elements[t]['question'] = 'Y';
                  temp_[i].elements[t]['outline'] =  {width: 2, color: '#00AFEE', style: 'solid'}
                  break;
                }
            }
        }
     // slidesStore.updateElement({id: handleElementId.value, ''})
     // addHistorySnapshot()
      //localStorage.setItem(handleElementId.value, 'document.getElementById(\'editable-element-' + handleElementId.value + '\').onclick = function(e) { alert("'+(<HTMLInputElement>document.getElementById("text_value")).value+'");}')


    }
    const question_no = () => {
     // alert(handleElementId.value)

     // localStorage.setItem(handleElementId.value, 'document.getElementById(\'editable-element-' + handleElementId.value + '\').onclick = function(e) { alert("오답");}')
      const temp_ = slidesStore.slides

      for (let i = 0; i < temp_.length ; i++) {
        for (let t = 0 ; t < temp_[i].elements.length ; t++ ){
          if(temp_[i].elements[t].id === handleElementId.value){
            temp_[i].elements[t]['question'] = 'N';
            temp_[i].elements[t]['outline'] ={width: 2, color: '#ff1e02', style: 'solid'}
            break;
          }
        }
      }

    }
    // 리치 텍스트 하이퍼링크 설정
    const link = ref('')
    const linkPopoverVisible = ref(false)

    watch(richTextAttrs, () => linkPopoverVisible.value = false)

    const openLinkPopover = () => {
      link.value = richTextAttrs.value.link
      linkPopoverVisible.value = true
    }
    const updateLink = (link?: string) => {
      if (link) {
        const linkRegExp = /^(https?):\/\/[\w\-]+(\.[\w\-]+)+([\w\-.,@?^=%&:\/~+#]*[\w\-@?^=%&\/~+#])?$/
        if (!linkRegExp.test(link)) return message.error('올바른 웹 링크 주소가 아닙니다')
      }
      emitRichTextCommand('link', link)
      linkPopoverVisible.value = false
    }

    return {
      fill,
      lineHeight,
      wordSpace,
      textIndent,
      paragraphSpace,
      richTextAttrs,
      availableFonts,
      webFonts,
      fontSizeOptions,
      lineHeightOptions,
      wordSpaceOptions,
      textIndentOptions,
      paragraphSpaceOptions,
      updateLineHeight,
      updateParagraphSpace,
      updateWordSpace,
      updateTextIndent,
      updateFill,
      emitRichTextCommand,
      emitBatchRichTextCommand,
      question_ok,
      question_no,
      presetStyles,
      link,
      linkPopoverVisible,
      openLinkPopover,
      updateLink,
      checkLadder,
      checkLadder2,
      quiz_1_value,
      quiz_2_value,
      quiz_3_value,
      quiz_4_value,
      pop_title,
    }
  },
})
