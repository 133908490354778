import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50d6e7b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "screen-preview" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { style: {"text-align":"center"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("iframe", {
      id: "ifrm",
      name: "ifrm",
      src: _ctx.iframe_data,
      width: "100%",
      height: "800",
      scrolling: "auto"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        type: "button",
        class: "btn",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
      }, " 닫기 ")
    ])
  ]))
}