
import Vue, {defineComponent, ref} from 'vue'
import {useSlidesStore} from '@/store'




export default defineComponent({

  setup(props, { emit }) {
    const close = () => emit('close')
    const url_data = ref<string>('')
    const iframe_data = ref<string>(process.env.VUE_APP_PREVIEW_SERVER_DOMAIN + '/admin?mode=screenpreview')
    const jsonResult = JSON.stringify(useSlidesStore().slides)

    // console.log('props:::' + JSON.stringify(props.class_no))
    // url_data.value = 'http://localhost:8081/student?mode=screenpreview&data=' + jsonResult
    // url_data.value = 'http://localhost:8080/admin?sub_code=' + props.sub_code + '&sub_name=' + props.sub_name + '&class_no=' + props.class_no + '&class_subject=' + props.class_subject + '&class_content=' + props.class_content + '&mode=screenpreview&data=' + jsonResult
    url_data.value = process.env.VUE_APP_PREVIEW_SERVER_DOMAIN + '/admin?mode=screenpreview&data=' + jsonResult

    console.log('url_data.value:::'+url_data.value)
    //   formIframe.submit()

    setIFrameData(url_data.value)
    return {
      close,
      jsonResult,
      url_data,
      iframe_data,

    }
  },
})


function setIFrameData(params: string) {
  // console.log("params:::::::::"+params)
  iFrameData = params
  // console.log('params:::::::::' + params.length)
  return params
}

function getrandom(lenth:number): string {
  let text = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`{}[]:;<>?,./|';
  for ( let i = 0; i < lenth; i++ ) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}
const random = getrandom(11)

let iFrameData = ''
window.addEventListener('message', function(e) {
  const ifrm: HTMLIFrameElement = document.getElementById('ifrm') as HTMLIFrameElement

  if (e.origin === process.env.VUE_APP_PREVIEW_SERVER_DOMAIN && ifrm !== null && ifrm.contentWindow !== null ) {
      // eslint-disable-next-line no-console
    console.log('random::::::::::', random)
    console.log('e.data::::::::::', e.data)
    if (e.data === 'sd^^&76*(&*jfdeoe1*df^^&*2') {
        console.log('random::::::::::>>>', random)
        console.log('e.data::::::::::>>>', e.data)
      ifrm.contentWindow.postMessage('requestToken' + random, process.env.VUE_APP_PREVIEW_SERVER_DOMAIN)
    }
    else if (e.data.length > 10 && e.data.substring(0, 11) === random) {
        console.log('iFrameData::::::::::>>>', iFrameData)

      ifrm.contentWindow.postMessage(iFrameData, process.env.VUE_APP_PREVIEW_SERVER_DOMAIN)
    }



  }
  else {
     //console.log( '***************'+ JSON.stringify( e.data))
  }
})





